@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials


//ADD BY REX
.no-underline {
  text-decoration: none;
  color: #000;
}

.no-underline:hover {
  text-decoration: underline;

}